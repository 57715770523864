import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"
import GridContainer from "../layout/GridContainer/GridContainer"
import HTMLWrapper from "../HTMLWrapper/HTMLWrapper"
import HuntWalkPagination from "../HuntWalkPagination/HuntWalkPagination"

// Import necessary Bulma CSS Framework components.
import "./HuntWalkStep.library.scss"

// Import component specific styles.
import styles from "./HuntWalkStep.module.scss"
import { Link } from "gatsby"

const HuntWalkStep = props => (
  <GridContainer>
    <div className={styles.huntWalkStep + " columns"}>
      <div className={styles.column + " column is-6"}>
        <h1 className={styles.title + " h1Heavy " + styles.titleMobile}>{props.title}</h1>
        {props.image !== null ?
          <div>
            <Img fluid={props.image.fluid} title={props.image.title} alt={props.image.alt} className={styles.imageMobile}/>
          </div>
          : ''
        }
        <div className={styles.textDesktop}>
          <h1 className={styles.title + " h1Heavy"}>{props.title}</h1>
          <p className={styles.text + " bodyWalk"}>
            <HTMLWrapper text={props.text}></HTMLWrapper>
          </p>
        </div>
        { props.ctaLink && props.ctaText && (
          <div className={styles.ctaDesktopWrapper + " " + styles.ctaWrapper}>
            <button tabindex="-1" className={styles.cta + " buttonText"} aria-label={'Begin ' + props.title}>
              {props.ctaLocation === "internal" ?
                <Link
                  to={props.ctaLink}
                  state={{ prevPath: props.currentPage, nextPage: props.nextPage }}
                  aria-label={'Begin ' + props.title}
                >
                  {props.ctaText}
                </Link>
                : <a href={props.ctaLink} target="_blank" rel="noreferrer" aria-label={'Begin ' + props.title}>{props.ctaText}</a>
              }
            </button>
          </div>
        )}

        { (props.previousPage || props.nextPage) && (
          <div className={styles.paginationDesktop}>
            <HuntWalkPagination
              nextButton={props.nextButton}
              nextPage={props.nextPage}
              backButton={props.backButton}
              previousPage={props.previousPage}
            />
          </div>
        )}
      </div>
      <div className={styles.column + " column is-6"}>
        <div className={styles.textMobile}>
          <p className={styles.text + " bodyWalk"}>
            <HTMLWrapper text={props.text}></HTMLWrapper>
          </p>
        </div>
        {props.image !== null ?
          <div>
            <Img fluid={props.image.fluid} alt={props.image.alt} title={props.image.title} className={styles.imageDesktop}/>
          </div>
          : ''
        }
      </div>
    </div>
    { props.ctaLink && props.ctaText && (
      <div className={styles.ctaMobileWrapper + " " + styles.ctaWrapper}>
        <button className={styles.cta + " buttonText"} aria-label={'Begin ' + props.title}>
          {props.ctaLocation === "internal" ?
            <Link
              to={props.ctaLink}
              state={{ prevPath: props.currentPage, nextPage: props.nextPage }}
              aria-label={'Begin ' + props.title}
            >
              {props.ctaText}
            </Link>
            : <a href={props.ctaLink} target="_blank" rel="noreferrer" aria-label={'Begin ' + props.title}>{props.ctaText}</a>
          }
        </button>
      </div>
    )}

    { (props.previousPage || props.nextPage) && (
       <div className={styles.paginationMobile}>
       <HuntWalkPagination
         nextButton={props.nextButton}
         nextPage={props.nextPage}
         backButton={props.backButton}
         previousPage={props.previousPage}
       />
     </div>
    )}
  </GridContainer>
)

HuntWalkStep.propTypes = {
  title: PropTypes.string,
  image: PropTypes.object,
  text: PropTypes.string,
  nextButton: PropTypes.object,
  nextPage: PropTypes.string,
  previousButton: PropTypes.object,
  previousPage: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLocation: PropTypes.string,
  currentPage: PropTypes.string,
}

HuntWalkStep.defaultProps = {
  title: ``,
  image: null,
  text: ``,
  nextButton: null,
  nextPage: null,
  previousButton: null,
  previousPage: null,
  ctaLink: ``,
  ctaText: ``,
  ctaLocation: ``,
  currentPage: ``,
}

export default HuntWalkStep
