import React from "react"
import { graphql } from "gatsby"
import HuntWalkStep from "../components/HuntWalkStep/HuntWalkStep"
import HuntWalkHeader from "../components/HuntWalkHeader/HuntWalkHeader"
import { FaFacebookSquare, FaMapMarkedAlt, FaChevronLeft, FaChevronRight } from "react-icons/fa"
import Layout from "../components/layout/Layout/Layout"

// Todo Add listing data for content types other than Pages
export const query = graphql`
  query($stepId: String!, $journeyId: String!) {
    datoCmsJourneyStep(id: { eq: $stepId }) {
      title
      image {
        alt
        fluid(maxWidth: 768, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        url
      }
      title
      stepDetails
      stepCta {
        ... on DatoCmsSecretLink {
          secret {
            originalId
          }
        }
        ... on DatoCmsContentLink {
          ctaText
          link {
            ... on DatoCmsLocalBusiness {
              id
              slug
            }
            ... on DatoCmsPointOfInterest {
              id
              slug
            }
            ... on DatoCmsPage {
              id
              slug
            }
            ... on DatoCmsSpotTheWildlife {
              id
              slug
            }
            ... on DatoCmsJourney {
              id
              slug
            }
          }
        }
        ... on DatoCmsExternalUrlLink {
          id
          ctaText
        }
      }
      location {
        latitude
        longitude
      }
    }
    datoCmsJourney(id: { eq: $journeyId }) {
      slug
      facebookLink
      steps {
        id
      }
    }
  }
`

const JourneyStep = props => {
  const previousPage = props.pageContext.stepNumber === 0 ?
    props.data.datoCmsJourney.slug :
    props.data.datoCmsJourney.slug + '/step/' + (props.pageContext.stepNumber)

  const nextPage = props.data.datoCmsJourney.steps.length - 1 === props.pageContext.stepNumber ?
    props.data.datoCmsJourney.slug + '/finish' :
    props.data.datoCmsJourney.slug + '/step/' + (props.pageContext.stepNumber + 2)

  const stepCTA = buildStepCta(
    props.data.datoCmsJourneyStep.stepCta,
    props.data.datoCmsJourney.slug + '/step/' + (props.pageContext.stepNumber + 1)
  )

  return (
    <Layout metaTitle={props.data.datoCmsJourneyStep.title} removeNav={true}>
      <HuntWalkHeader
        currentPage={props.data.datoCmsJourney.slug + '/step/' + (props.pageContext.stepNumber + 1)}
        stepNumber={props.pageContext.stepNumber + 1}
        totalSteps= {props.data.datoCmsJourney.steps.length}
        mapLink={props.data.datoCmsJourney.slug + '/step/' + (props.pageContext.stepNumber + 1) + '/map'}
        socialMedia={<FaFacebookSquare />}
        socialMediaLink={props.data.datoCmsJourney.facebookLink}
        map={<FaMapMarkedAlt />}
      />
      <HuntWalkStep
        title={props.data.datoCmsJourneyStep.title}
        image={props.data.datoCmsJourneyStep.image}
        text={props.data.datoCmsJourneyStep.stepDetails}
        nextButton={<FaChevronRight />}
        nextPage={nextPage}
        backButton={<FaChevronLeft />}
        previousPage={previousPage}
        ctaLink={stepCTA.link}
        ctaText={stepCTA.text}
        ctaLocation={stepCTA.location}
        currentPage={props.data.datoCmsJourney.slug + '/step/' + (props.pageContext.stepNumber + 1)}
      />
    </Layout>
  )
}

// Helper function to get the correct link type and pass to component.
function buildStepCta(stepCTA, currentPage) {
  var builtCTA = {}

  if (stepCTA.length < 1) {
    builtCTA.text = null
    builtCTA.link = null
    builtCTA.location = null
  }
  else if (stepCTA[0].__typename === "DatoCmsSecretLink") {
    builtCTA.text = "Selsey Secret"
    builtCTA.link = currentPage + '/secret'
    builtCTA.location = "internal"
  }
  else if (stepCTA[0].__typename === "DatoCmsContentLink") {
    builtCTA.text = stepCTA[0].ctaText
    builtCTA.link = '/' + stepCTA[0].link.slug
    if (stepCTA[0].link.__typename === "DatoCmsSpotTheWildlife") {
      builtCTA.location = "internal"
      builtCTA.link = '/' + currentPage + '/spot-the-wildlife'
    } else {
      builtCTA.location = "external"
    }
  } else if (stepCTA[0].__typename === "ExternalUrlLink") {
    builtCTA.text = stepCTA[0].ctaText
    builtCTA.link = stepCTA[0].url
    builtCTA.location = "external"
  }

  return builtCTA
}

export default JourneyStep
