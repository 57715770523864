import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import GridContainer from "../layout/GridContainer/GridContainer"

// Import necessary Bulma CSS Framework components.
import "./HuntWalkPagination.library.scss"

// Import component specific styles.
import styles from "./HuntWalkPagination.module.scss"

const HuntWalkPagination = props => (
  <GridContainer>
    <div className={styles.pagination}>
      { props.previousPage && (
          <Link to={props.previousPage} aria-label="Previous page" className={styles.backButtonLink}>
          <div className={styles.backButton}>
            {props.backButton}
          </div>
        </Link>
      ) }

      { props.nextPage && (
        <Link to={props.nextPage} aria-label="Next Step" className={styles.nextButtonLink}>
          <div className={styles.nextButton}>
            {props.nextButton}
          </div>
        </Link>
      )}
    </div>
  </GridContainer>
)

HuntWalkPagination.propTypes = {
  previousPage: PropTypes.string,
  backButton: PropTypes.object,
  nextPage: PropTypes.string,
  nextButton: PropTypes.object,
}

HuntWalkPagination.defaultProps = {
  previousPage: null,
  backButton: <FaChevronLeft />,
  nextPage: null,
  nextButton: <FaChevronRight />,
}

export default HuntWalkPagination
